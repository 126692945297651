const PALETTE: string[] = [
    // http://alumni.media.mit.edu/~wad/color/palette.html
    // Black
    "rgb(0, 0, 0)",
    // Dk. Gray
    "rgb(87, 87, 87)",
    // Red
    "rgb(173, 35, 35)",
    // Blue
    "rgb(42, 75, 215)",
    // Green
    "rgb(29, 105, 20)",
    // Brown
    "rgb(129, 74, 25)",
    // Purple
    "rgb(129, 38, 192)",
    // Lt. Gray
    "rgb(160, 160, 160)",
    // Lt. Green
    "rgb(129, 197, 122)",
    // Lt. Blue
    "rgb(157, 175, 255)",
    // Cyan
    "rgb(41, 208, 208)",
    // Orange
    "rgb(255, 146, 51)",
    // Yellow
    "rgb(255, 238, 51)",
    // Tan
    "rgb(233, 222, 187)",
    // Pink
    "rgb(255, 205, 243)",
    // White
    "rgb(255, 255, 255)"
];

export const getPaletteSize = (): number => PALETTE.length;
export const getPaletteColour = (index: number): string => PALETTE[index];

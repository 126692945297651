import Konva from "konva";
import {
    COLOUR_PICKER_HEIGHT,
    COLOUR_PICKER_WIDTH,
    COLOUR_PICKER_X,
    COLOUR_PICKER_Y
} from "./layout";
import { getPaletteColour, getPaletteSize } from "./colour";
import KonvaEventObject = Konva.KonvaEventObject;

export class ColourPicker {
    private currentColourIndex: number;
    private currentRect: Konva.Rect | undefined;

    constructor() {
        this.currentColourIndex = 13;
    }

    draw(layer: Konva.Layer) {
        this.drawPalette(layer);
    }

    getCurrentColourIndex(): number {
        return this.currentColourIndex;
    }

    private drawPalette(layer: Konva.Layer) {
        const colourCount = getPaletteSize();
        const totalPixels =
            COLOUR_PICKER_WIDTH > COLOUR_PICKER_HEIGHT
                ? COLOUR_PICKER_HEIGHT
                : COLOUR_PICKER_WIDTH;
        const side = Math.ceil(Math.sqrt(colourCount));
        const sidePixels = Math.floor(totalPixels / side) - 6;
        for (let colourIndex = 0; colourIndex < colourCount; colourIndex++) {
            const col = colourIndex % side;
            const row = (colourIndex - col) / side;
            const x = col * (sidePixels + 6) + COLOUR_PICKER_X + 3;
            const y = row * (sidePixels + 6) + COLOUR_PICKER_Y + 3;
            const colourRect = new Konva.Rect({
                x,
                y,
                width: sidePixels,
                height: sidePixels,
                fill: getPaletteColour(colourIndex),
                stroke: "#000000",
                strokeWidth: 5,
                shadowForStrokeEnabled: false,
                strokeEnabled: false
            });
            colourRect.on("click tap", (evt: KonvaEventObject<MouseEvent>) => {
                this.currentColourIndex = colourIndex;

                if (this.currentRect !== undefined) {
                    this.currentRect.strokeEnabled(false);
                }

                const rect = evt.target as Konva.Rect;
                rect.strokeEnabled(true);

                this.currentRect = rect;
                layer.draw();
            });
            layer.add(colourRect);
        }
    }
}

import { Pattern, createPattern } from "./src/pattern";
import { Position } from "./src/position";
import Konva from "konva";
import { ColourPicker } from "./src/colourPicker";
import { getPaletteColour } from "./src/colour";

const PATTERN_RADIUS: number = 15;
const PATTERN_GAP: number = -6;
const PATTERN_CENTRE_GAP: number = PATTERN_GAP + 2 * PATTERN_RADIUS;
const PATTERN_PADDING: number = 30;

const WHEEL_CENTRE: Position = new Position(500, 500);
const WHEEL_INNER_RADIUS: number = 50;
const WHEEL_OUTER_RADIUS: number = 260;
const BEAD_RADIUS = 6;

// The magic pattern: the bead with index i goes on thread MAGIC[i % 16]
const MAGIC: number[] = [0, 6, 4, 2, 7, 5, 3, 1, 4, 2, 0, 6, 3, 1, 7, 5];
const ANGLES: number[] = [7, 1, 31, 25, 23, 17, 15, 9];

const colourPicker = new ColourPicker();

function patternPosition(row: number, col: number, width: number): Position {
    const limit = width * 2;
    const x = PATTERN_CENTRE_GAP * row;
    const y = PATTERN_CENTRE_GAP * ((2 * col + row) % limit);
    return new Position(PATTERN_PADDING + x, PATTERN_PADDING + y);
}

function drawPattern(layer: Konva.Layer, pattern: Pattern) {
    const length = pattern.getLength();
    const width = pattern.getWidth();

    for (let row = 0; row < length; row++) {
        for (let col = 0; col < width; col++) {
            const colourIndex = pattern.getColourIndex(row, col);
            const position = patternPosition(row, col, width);
            const circle = new Konva.Circle({
                x: position.x,
                y: position.y,
                radius: PATTERN_RADIUS,
                fill: getPaletteColour(colourIndex)
            });
            pattern.setPatternCircle(row, col, circle);
            circle.on("click tap", () => {
                pattern.setColourIndex(
                    row,
                    col,
                    colourPicker.getCurrentColourIndex()
                );
                layer.draw();
            });
            layer.add(circle);
        }
    }
}

function drawThreads(layer: Konva.Layer) {
    const c = WHEEL_CENTRE;
    for (let i = 0; i < ANGLES.length; i++) {
        const angle = ANGLES[i];
        const ix = angleX(angle) * WHEEL_INNER_RADIUS;
        const iy = angleY(angle) * WHEEL_INNER_RADIUS;
        const ox = angleX(angle) * WHEEL_OUTER_RADIUS;
        const oy = angleY(angle) * WHEEL_OUTER_RADIUS;
        const line = new Konva.Line({
            points: [c.x + ix, c.y + iy, c.x + ox, c.y + oy],
            stroke: "black",
            width: 1.0
        });
        layer.add(line);
    }
}

function drawBeads(layer: Konva.Layer, pattern: Pattern) {
    const length = pattern.getLength();
    const width = pattern.getWidth();
    const c = WHEEL_CENTRE;

    let index = 0;
    for (let row = 0; row < length; row++) {
        for (let col = 0; col < width; col++) {
            const colourIndex = pattern.getColourIndex(row, col);
            const angle = ANGLES[MAGIC[index % 16]];
            const distance =
                WHEEL_INNER_RADIUS +
                BEAD_RADIUS * (2 + 2 * Math.floor(index / 8));
            const x = angleX(angle) * distance;
            const y = angleY(angle) * distance;
            const circle = new Konva.Circle({
                x: c.x + x,
                y: c.y - y,
                radius: BEAD_RADIUS,
                fill: getPaletteColour(colourIndex)
            });
            pattern.setThreadCircle(row, col, circle);
            layer.add(circle);
            index++;
        }
    }
}

function angleX(angle: number): number {
    return Math.cos((angle * 2 * Math.PI) / 32);
}

function angleY(angle: number): number {
    return Math.sin((angle * 2 * Math.PI) / 32);
}

function drawWheel(layer: Konva.Layer, pattern: Pattern) {
    drawThreads(layer);
    drawBeads(layer, pattern);
}

function draw() {
    const stage = new Konva.Stage({
        container: "tutorial",
        width: 1280,
        height: 1440
    });
    const layer = new Konva.Layer();

    colourPicker.draw(layer);

    const pattern = createPattern();
    drawPattern(layer, pattern);
    drawWheel(layer, pattern);

    stage.add(layer);
    layer.draw();
}

window.onload = () => draw();

import { Circle } from "konva/types/shapes/Circle";
import { getPaletteColour } from "./colour";

export interface Pattern {
    getLength(): number;

    getWidth(): number;

    getColourIndex(row: number, col: number): number;

    setColourIndex(row: number, col: number, colour: number): void;

    setPatternCircle(row: number, col: number, circle: Circle): void;

    setThreadCircle(row: number, col: number, circle: Circle): void;
}

class Cell {
    private colour: number;
    private patternCircle: Circle | undefined;
    private threadCircle: Circle | undefined;

    constructor(colour: number) {
        this.colour = colour;
    }

    setColourIndex(colour: number) {
        this.colour = colour;
        if (this.patternCircle !== undefined) {
            this.patternCircle.setAttr("fill", getPaletteColour(colour));
        }
        if (this.threadCircle !== undefined) {
            this.threadCircle.setAttr("fill", getPaletteColour(colour));
        }
    }

    getColourIndex() {
        return this.colour;
    }

    setPatternCircle(circle: Circle) {
        this.patternCircle = circle;
    }

    setThreadCircle(circle: Circle) {
        this.threadCircle = circle;
    }
}

class SimplePattern implements Pattern {
    private pattern: Cell[][];

    constructor() {
        let pattern = [];
        for (let i = 0; i < 28; i++) {
            let row = [];
            for (let j = 0; j < 4; j++) {
                row.push(j % 2 == 0 ? new Cell(3) : new Cell(10));
            }
            pattern.push(row);
        }
        this.pattern = pattern;
    }

    setColourIndex(row: number, col: number, colourIndex: number): void {
        this.pattern[row][col].setColourIndex(colourIndex);
    }

    getLength(): number {
        return this.pattern.length;
    }

    getWidth(): number {
        return this.pattern[0].length;
    }

    getColourIndex(row: number, col: number): number {
        return this.pattern[row][col].getColourIndex();
    }

    setPatternCircle(row: number, col: number, circle: Circle): void {
        this.pattern[row][col].setPatternCircle(circle);
    }

    setThreadCircle(row: number, col: number, circle: Circle): void {
        this.pattern[row][col].setThreadCircle(circle);
    }
}

const pattern = new SimplePattern();

export function createPattern(): Pattern {
    return pattern;
}
